console.log("JS OK 2");

////////////////////////////
// MENU MOBILE
var btnMobile = document.querySelector('.navMobileBurger');
var element = document.getElementById("header");
if (btnMobile){

  //Ouvrir more text
  btnMobile.addEventListener('click', function() {
    console.log('click menu');
  element.classList.toggle("headerFullHeight");
  });

}



// UNIQUEMENT SUR les liste projets
var grid = document.querySelector('#listProjet');

if ( grid ) {

/////////////////////////////
// MASONRY
// https://masonry.desandro.com
//-------------------------------------//
var msnry = new Masonry( grid, {
  itemSelector: 'none', // select none at first
  columnWidth: '.grid-sizer',
  gutter: '.gutter-sizer',
  percentPosition: true,
  stagger: 30,
  // nicer reveal transition
  visibleStyle: { transform: 'translateY(0)', opacity: 1 },
  hiddenStyle: { transform: 'translateY(100px)', opacity: 0 },
});


// initial items reveal
imagesLoaded( grid, function() {
  grid.classList.remove('are-images-unloaded');
  msnry.options.itemSelector = '.grid-item';
  var items = grid.querySelectorAll('.grid-item');
  msnry.appended( items );
});

// //-------------------------------------//
// // init Infinte Scroll PROJET
// var infScroll = new InfiniteScroll( grid, {
//   path: '.nextPageLink a',
//   append: '.blocMiniature',
//   outlayer: msnry,
//   status: '.page-load-status',
//   history: false,
//   hideNav: '.nextPrev'
// });

} //FIN IF
